import * as React from "react";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import GeneralTab from "../../components/HTabs";
import Service from "../../components/Services";
import Document from "../../components/Document";
import ImgContent from "../../components/GSTTemp/ImgContent";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function DueDiligence() {
  /* Slider */
  // const DdSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     marginTop: 40,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83)`,
  //     header: `Due Diligence`,
  //     content: `TODAYFILINGS, India's largest due diligence company, can assist you with your`,
  //     image: "/imgs/business/sliders/subsidiary-slider.png",
  //     alt_tag: "Best Online Advisory Service for due diligence in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Due Diligence`,
    buyBtnLink: `#pricing-buy`,
    price: `14999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data Without Form*/
  const DdAboutData = {
    title: `Due Diligence`,
    sub_title: `Online Advisory Service for due diligence`,
    paragraph: `Due diligence is a procedure of verification that also entails an investigation
            or audit of a potential deal or investment opportunity in order to verify all
            pertinent facts and financial information and to examine everything that was
            raised throughout any investment process or M&A deal. Prior to a business transaction
            closing, due diligence is conducted to give the buyer a guarantee of what
            they are purchasing.`,
    header: `Due Diligence in India
            is often utilized as a pre-investment intelligence technique by investing
            partners in order to receive an unbiased and sophisticated report.`,
    points: [],
  };

  /* Img Content Component Data */
  const DdIcData = {
    id: '',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Why TODAYFILINGS For Due Diligence?',
    image: '/imgs/gifs/financial-auditing.gif',
    alt_tag: 'Due Diligence',
    Paragraph: '',
    points: [
      {
        content: `Endurance, energy, and assurance in ability to solve problems and adhere to deadlines.`,
        icon: true,
      },
      {
        content: `Combining commercial recognition with actual experience and a questioning mindset.`,
        icon: true,
      },
      {
        content: `Determine the Key Drivers to ensure a focused strategy and to offer
                    the necessary alert in all problem areas and disparities.`,
        icon: true,
      },
      {
        content: `Presentation Personal qualities and the capacity for unambiguous communication.`,
        icon: true,
      },
      {
        content: `Related well-trained expertise can offer pertinent and focused due
                    diligence input that aids in assessing events.`,
        icon: true,
      },
    ],
  };
  /* Document Section Data */
  const DdDocData = {
    id: 'dd-doc',
    heading: `Benefits of Performing Due Diligence`,
    sub_heading: `Due diligence is required since the corporation is fully aware of 
            all the essential components, including:`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/it/administration.png`,
        alt_tag: `Administration`,
        header: `Administration`,
        description: `Research or in-depth investigation of the company's 
                    management or the owners of the business.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/it/competitor.png`,
        alt_tag: `Industries and competitors`,
        header: `Industries and competitors`,
        description: `For a better grasp of the target firm and its rivals, 
                    do out research and examine the company's limits of competitors.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/it/capitalization.png`,
        alt_tag: `Capitalization`,
        header: `Capitalization`,
        description: `Examination of the entity's and the market's size and 
                    volatility. It is vital to analyze both of them in contrast.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/it/balance-sheet.png`,
        alt_tag: `Balance sheet analysis and Expectations`,
        header: `Balance sheet Expectations`,
        description: `Evaluating the company's debt-to-equity ratio in order to boost future profits.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/it/profit.png`,
        alt_tag: `Earnings and Profit`,
        header: `Earnings and Profit`,
        description: `To confirm and look for any recent trends in the data, whether 
                    they be rising, stable, or declining.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/it/possibilities.png`,
        alt_tag: `Possibilities and choices`,
        header: `Possibilities and choices`,
        description: `Regarding the company's consistently high stock price for a 
                    lengthy or short period of time? organization managing.`,
      },
    ],
  };

  /* Service Data */
  const DueDiligenceData = {
    header: `Types of due diligence`,
    content: `Due diligence (DD) is a comprehensive procedure that an
            acquiring company goes through to fully and accurately
            evaluate the business, assets, capabilities, and financial
            performance of the target company. There could be twenty or
            more different perspectives included in the analysis.`,
    image: '/imgs/ddtypes.png',
    alt_img: 'TODAYFILINGS Due Diligence Services',
    points: [
      `Commercial Due Diligence`,
      `Legal Due Diligence`,
      `Due Diligence on finances`,
      `People Due Diligence`,
      `Due Diligence for the Environment`,
      `IT Due Diligence`,
    ],
  };

  /* FaQ Data */

  var DueDiligenceFAQ = [
    {
      header: 'What foundational elements make up due diligence?',
      body: [
        {
          content: `Risk mitigation, persuasive justification, and post-diligence are
          the pillars.`,
          icon: true,
        },
      ],
    },
    // 2
    {
      header: 'What advantages come from performing due diligence?',
      body: [
        {
          content: `These are the advantages of performing due diligence.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How are preparers selected for a due diligence audit? ',
      body: [
        {
          content: `TODAYFILINGS selects preparers for due diligence visits based on the likelihood that the returns 
          they prepare are in error. We determine the likelihood based on a set of standard criteria applied 
          to all returns. See What to Expect during a Due Diligence Audit for additional information.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Must I use Form 8867 as part of the due diligence process? ',
      body: [
        {
          content: `Yes, you must complete, submit, and keep a copy of Form 8867, Paid Preparer's Due Diligence 
          Checklist, for every return or claim for refund reporting head of household filing status, the EITC, 
          CTC/ACTC/ODC or AOTC. You must submit the form as part of an electronic return or attach it to a paper
           return.`,
          icon: false,
        },
      ],
    },
    {
      header:
        "I use good return preparation software. Why can't I rely on my software to meet my due diligence requirements? ",
      body: [
        {
          content: `You can’t depend on your software exclusively. Tax software is a tool to assist you and is not a 
          substitute for your knowledge of the tax law, professional judgment, and responsibility. You are the person 
          who can best evaluate the information your client gives you and apply your knowledge of the law to that 
          information. Software cannot be designed to address every possible due diligence issue you may encounter.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'How did the PATH Act of 2015 change my due diligence requirements?',
      body: [
        {
          content: `All paid tax return preparers who determine eligibility for, or the amount of, the EITC, 
          CTC/ACTC or the AOTC for tax year 2016 or later are subject to due diligence requirements and to the 
          penalties for failure to comply with these requirements. The penalties apply to preparers who sign the 
          return, preparers who prepare the applicable credit portion of a return but do not sign the return, 
          and employers of these preparers.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'How can I as an employer protect myself from penalties caused by my employees not meeting due diligence requirements? ',
      body: [
        {
          content: `If you employ tax return preparers, here are some examples of how you can protect yourself from due 
          diligence penalties:`,
          icon: false,
        },
        {
          content: `Review your current office procedures to make sure they address all appropriate due diligence 
          requirements.`,
          icon: true,
        },
        {
          content: `Review your procedures with your employees to make sure they clearly understand their 
          responsibilities and your expectations of them.`,
          icon: true,
        },
        {
          content: `Conduct annual due diligence training or instruct your staff to complete the online 
          training module that we offer in both English and Spanish.`,
          icon: true,
        },
        {
          content: `Test your employees’ knowledge of due diligence requirements and your procedures.`,
          icon: true,
        },
        {
          content: `Perform recurring quality review checks on your employees’ work, including credit 
          computations, questions asked clients, documents reviewed, and the records kept.`,
          icon: true,
        },
        {
          content: `Ensure all records are properly maintained.`,
          icon: true,
        },
      ],
    },
    {
      header: `What kinds of due diligence are there?`,
      body: [
        {
          content: `Business due diligence`,
          icon: true,
        },
        {
          content: `Legal due diligence`,
          icon: true,
        },
        {
          content: `Financial due diligence`,
          icon: true,
        },
        {
          content: `People due diligence`,
          icon: true,
        },
        {
          content: `Environmental due diligence`,
          icon: true,
        },
      ],
    },
  ];
  /* Tabs Data */
  const DueDiligenceTabsData = {
    id: 'features',
    heading: 'Procedures for due diligence',
    paragraph: `Examine and confirm the facts and particulars mentioned during
        the investment or transaction process.Identify any potential flaws in the investment, contract, or transaction
        opportunity in order to avoid engaging in dishonest commercial practices
        or engaging in unethical operations.Gather all the data and information required for the evaluation of the offer Ensure
        that the investment or deal opportunity complies with the requirements for investments or deals.`,
    body: [
      {
        tab_title: 'Capitalization',
        tab_content: [
          {
            content_title: `Check your Capitalization`,
            content_paragraph: `The market capitalization, or total investment, of a firm
                    measures the scope of its operations, the volatility of
                    its stock price, and the potential size of its commercial
                    marketplaces.`,
            points: [],
          },
          {
            content_title: 'Resources, Results, and Margin',
            content_paragraph: `The company's net income, revenue, and profit will be
                        listed in the income report or financial report. Examining
                        historical trends in a company's profit, operating
                        exposure, profit margins, and return on invested capital
                        is crucial.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/capitalization.png',
        alt_img: 'Due Diligence ',
      },
      {
        tab_title: 'Competitors',
        tab_content: [
          {
            content_title: 'Comparative Analysis of Competitors',
            content_paragraph: `Every company's growth is determined by its rivals or
                            competitors. Examine its competitors' profit margins. The
                            creditors can get a clear picture of how the company is
                            doing and what kinds of business operations have the
                            competitive edge in it by performing due diligence on
                            various companies operating in the same industry.`,
            points: [],
          },
          {
            content_title: 'Value Multiplier',
            content_paragraph: `To analyze companies, a variety of economic indicators and
                            ratios are employed. However, the "price/earnings to
                            growth" (PEGs), "price-to-earnings" (P/E), and
                            "price-to-sales" (P/S) ratios are three of the most
                            important metrics.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/competitors.png',
        alt_img: 'Best Due Diligence service near Bangalore',
      },
      {
        tab_title: 'Administration',
        tab_content: [
          {
            content_title: 'Management and Share Ownership',
            content_paragraph: `Determining whether the board of directors has been
                            reorganized to include many new features or whether the
                            company is still run by its founders. Being founder-led is
                            aided by younger entities. On the company's official
                            website, you may read executive biographies to learn more
                            about their degree of knowledge and experience.`,
            points: [],
          },
          {
            content_title: 'Balance Sheet',
            content_paragraph: `The company's assets and obligations, as well as how
                            wealthy money is possible, will be displayed on the
                            consolidated balance sheet. To determine the amount of
                            tangible equity held by the corporation, look at the
                            debt-to-equity ratio.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/administration.png',
        alt_img: 'Due Diligence Administration',
      },
      {
        tab_title: 'Stock',
        tab_content: [
          {
            content_title: 'Stock Costing History',
            content_paragraph: `Investors should examine the stock's "long-term price" and
                            "short-term" movement, as well as if the investment has
                            been consistent or volatile. Connect the company's
                            previous gains and earnings to determine how it responds
                            to pricing changes.`,
            points: [],
          },
          {
            content_title: `Stock Suspension`,
            content_paragraph: `The number of shares that the company owns and how that
                            link to the business relationship is the subject of
                            research. Are there any problems with the company's
                            shares? If so, the stock rate could suffer.`,
            points: [],
          },
          {
            content_title: `Consider Both Long-Term And Short-Term Risks`,
            content_paragraph: `Examine and comprehend both company-specific and
                            sector-specific hazards. Exist any pending legal issues or
                            regulatory issues? Exists any shady management?`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/stock.png',
        alt_img: 'Best Due Diligence in Hosur',
      },
    ],
  };

  return (
    <div>
      <Seo
        title='Due Diligence'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={DdSlider} /> */}

          <Package packageData={PackageCompData} />

          <About about_data={DdAboutData} />

          <Counter />

          <ImgContent item={DdIcData} />

          <Service item={DueDiligenceData} />
          <Document docSecData={DdDocData} />

          <GeneralTab HTabsData={DueDiligenceTabsData} />
          <Cta />
          <FAQAccord items={DueDiligenceFAQ} />
        </main>
      </Layout>
    </div>
  );
}
